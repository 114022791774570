//overrides
// TODO : review

@import './themeChanges.scss';
@import './utils.scss';
@import 'base/colors'; //css color variables
@import 'base/fonts';
@import 'base/sizes';

@import './containers/socialfeed.scss';
@import './containers/termsAndConditions.scss';

@import 'components/header.scss';
@import 'components/sidebar.scss';
// @import 'components/details-tab-layout.scss';
@import 'components/balance-position.scss';
@import 'components/BFCDatatable.scss';

@import 'gajanan/scss/style.scss';
@import 'remove_this_file.scss';
// @import 'containers/offers.scss';
// @import 'containers/deals.scss';

// @import 'ak.scss';
@import 'at.scss';
@import 'remove_this_file.scss';
@import 'subscription.scss';
@import 'SubscriptionDetails.scss';
@import 'antTable.scss';
@import 'skeleton.scss';


// @import './containers/account.scss';

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
   opacity: 1;
   cursor: pointer;
}

.rc-notification {
  z-index: 1100;
  // display: none;
  h5.notification__title {
    color: var(--unnamed-color-ffffff) !important;
  }
}

.form-control {
  font-size: var(--fs-base__three) !important;
}

h1.h1--title {
  color: var(--unnamed-color-1b62ab);
  text-align: left;
  font: normal normal 600 28px Segoe UI;
  letter-spacing: 0px;
}

.center-items {
  display: flex;
  align-items: center;
}

.highlighted-text {
  em {
    font-style: normal;
    font-weight: bold;
  }
}

.has-error {
  input {
    border: 1px solid #f44336 !important;
  }
}

.no-title-rating {
  .star-ratings {
    pointer-events: none;
  }
}

.login-card {
  .react-tel-input {
    .form-control {
      width: 96%;
    }
  }
}
.signup-card {
  @extend .login-card;
}

.react-tel-input {
  .form-control {
    width: 100%;
  }
}

.phone-alert {
  .alert-danger {
    .close {
      font-size: 26px;
      top: 0;
    }
  }
}

.bfc-alert-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 60px;
  z-index: 99999;
  // .top-banner-alert{
  //   margin-left: -28%;
  //   margin-top: -5%;
  //   position: fixed;
  //   width: 100%;
  // }
  .alert-close-icon {
    margin-top: -5px;
  }
  .alert__content {
    margin: 0 auto;
    .bold-text {
      font-size: var(--fs-base__four);
    }
  }
}

.form__form-group-error {
  color: #f44336;
  font-size: var(--fs-base__four) !important;
}

.reference-data-select {
  width: 100%;
}

.bfc-clr-blue {
  color: #70bbfd !important;
}

.bfc-invisible {
  visibility: hidden !important;
}

.bfc-loader {
  display: flex;
  background: #3333333b;
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 0px;
  right: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.red {
  background-color: red;
}

.blue {
  border: 2px solid blue;
}

.green {
  border: 1px solid green;
}

.td-iconwrapper a {
  color: var(--unnamed-color-000);
}
a {
  cursor: pointer;
  color: var(--unnamed-color-1b62ab) !important;
}

//TODO : review - move to appropriate place

.bfc-post-text-editor-modal {
  display: grid !important;
  align-items: baseline;
  justify-content: center;

  // display: flex !important;
  // flex-direction: column !important;
  // justify-content: center!important;
  .modal-dialog {
    padding: 0.5rem;
    max-width: 800px;
  }
  .bfc-modal-postbutton {
    color: var(--unnamed-color-ffffff);
    //  margin-bottom: 7px;
    // margin: 3px;
    width: auto;
    height: 36px; //32px
    background: var(--unnamed-color-1b62ab) 0% 0% no-repeat padding-box;
    border-radius: 30px;
    opacity: 1;
    position: absolute;
    right: 20px;
    bottom: 10px;
  }

  .bfc-modalTitle {
    color: var(--unnamed-color-1b62ab);
    font-size: var(--fs-base__one);
  }

  .bfc-postModalHorizontalLIne {
    width: 10%;
    color: var(--unnamed-color-1b62ab);
  }

  .bfc-modal-profileName {
    font-size: var(--fs-base__two);
    color: var(--unnamed-color-1b62ab);
    margin-top: 4%;
  }

  .bfc-socialfeed-postModalFilename {
    // background-color: red;
    width: 340px;
    text-align: right;
    // position: absolute;
    // right: 70px;
    // bottom: 10px;
  }

  .bfc-post-thumbnail {
    width: 60px;
    height: 60px;
  }

  .bfc-modalPostbox {
    border: 1px solid #dbdbdb;
    border-radius: 10px;
    // margin: 10px;
  }
  .slick-slider {
    .slick-slide {
      margin-top: 8px;
      padding-right: 10px;
    }
  }

  .modal-dialog {
    max-width: none !important;
    // width: 100%;
    width: 650px;
    // margin-top: auto;
    // margin-bottom: auto;
    .bid-offer__header {
      padding: 0.5rem 1.5rem !important;
    }
    .accept-header{
      padding: 1rem 1.5rem !important;
    }
    .text-editor {
      .DraftEditor-root {
        min-height: 50px !important;
      }
      .rdw-editor-toolbar {
        display: none !important;
      }
      .public-DraftEditorPlaceholder-inner {
        padding: 6px 10px;
      }
    }
    .text-editor-about-me {
      .DraftEditor-root {
        min-height: 50px !important;
      }

      .public-DraftEditorPlaceholder-inner {
        padding: 6px 10px;
        font-size: var(--fs-base__three);
      }
    }

    .dropzone {
      &.dropzone--multiple {
        min-height: 0;
        .dropzone__input {
          min-height: 0;
        }

        .dropzone__img {
          width: 100%;
          background-size: contain;
        }
      }
    }
    .modal-content {
      .formik-form {
        .counter-modal__body {
          padding-left: 12%;
          padding-right: 12%;
          padding-top: 20px;
          padding-bottom: 20px;
        }
        .modal__body {
          // height: calc(100vh - 180px);
          height: calc(100vh - 140px);
          max-height: 470px;
          // max-height: 460px;
          // max-height: 420px;
          width: 100%;
          // overflow-y: scroll;
          // overflow-x: auto;
          overflow-x: hidden;
          .notes-block {
            p {
              margin: 0;
              border-radius: 3px;
              font-size: var(--fs-base__three);
              padding: 5px 15px;
              &.redbg {
                background-color: rgba(255, 0, 0, 0.05);
                color: var(--unnamed-color-d11010);
                box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
                border: 1px solid #c88682;
              }
              &.yellowbg {
                // background-color: rgba(236, 250, 176, 0.945);
                background-color: #fffcce;
                color: var(--unnamed-color-000);
                box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
                // border: 1px solid hsl(64, 98%, 50%);
                border: 1px solid #e8e393;
              }
              &.greenbg {
                background-color: rgba(2, 177, 2, 0.05);
                color: var(--unnamed-color-3c763d);
                box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
              }
            }
          }
          .custom-input__both {
            .react-datepicker {
              right: 100px!important;
              .react-datepicker__triangle {
                left: 120px!important;
              }
            }
          }
        }
        .normal-modal__body {
          @extend .modal__body;
          height: calc(100vh - 180px);
          max-height: 340px;
          overflow-x: hidden;
          .custom-input {
            .react-datepicker {
              right: 80px!important;
            }
          }
        }
        .medium-modal__body {
          @extend .modal__body;
          height: calc(100vh - 145px);
          // max-height: 320px;
          max-height: 450px;
          overflow-x: hidden;
          .custom-input {
            .react-datepicker {
              right: 70px!important;
            }
          }
        }
        .accept-reject-modal__body {
          @extend .modal__body;
          height: calc(100vh - 200px);
          max-height: 400px;
          overflow-x: hidden;
        }
        .normal-accept-reject-modal__body {
          @extend .accept-reject-modal__body;
          height: calc(100vh - 180px);
          // max-height: 260px;
          max-height: 300px;
          overflow-x: hidden;
        }
        .medium-accept-reject-modal__body {
          @extend .accept-reject-modal__body;
          // height: calc(100vh - 180px);
          height: calc(100vh - 180px);
          // max-height: 270px;
          max-height: 380px;
          overflow-x: hidden;
        }
        .invoice-aproval-footer {
          display: block;
          .chat-care {
            margin: 0;
            padding: 15px 10% 15px 10%;
          }
          .chat {
            list-style: none;
            padding: 0;
            ul{
              margin-bottom: 0px;
              li {
                width: 100%;
                .admin {
                  .heading {
                    background-color: #f7f7f7;
                    color: var(--unnamed-color-32a836);
                    font-weight: bold;
                    font-size: var(--fs-base__four);
                  }
                  .chat-body {
                    display: inline-block;
                    box-shadow: 0px 2px 5px 2px rgba(151, 142, 142, 0.2);
                    max-width: 100%;
                    background-color: var(--unnamed-color-ffffff);
                    border-radius: 12.5px;
                    width: 100%;
                    .header {
                      text-align: left;
                      background-color: #35858d08;
                      padding: 10px;
                      color: var(--unnamed-color-707070);
                      border-radius: 10px 10px 0px 0px;
                      font-size: var(--fs-base__three);
                      .right-subtext {
                        font-weight: bold;
                      }
                    }
                  }
                  .chat-msg {
                    text-align: left;
                    margin: 0px;
                    padding: 10px;
                    font-size: var(--fs-base__three);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//TODO : review - tmp fixes
.theme-light.ltr-support .bfc-html .table-farms tbody tr td {
  color: var(--unnamed-color-707070) !important;
  font-family: var(--unnamed-font-segoeUI) !important;
  word-break: break-word;
}

.theme-light.ltr-support .bfc-html .table-farms thead .th-farm {
  color: var(--unnamed-color-707070) !important;
  white-space: normal;
}

.account__wrapper {
  .has-error {
    .MuiAutocomplete-inputRoot {
      .MuiAutocomplete-input {
        border: unset !important;
      }
    }
  }
}

.bfc-html .table-farms thead {
  background-color: #f1f2f3;
}
.bfc-html .table-farms thead th {
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  font-family: var(--unnamed-font-segoeUI);
  color: var(--unnamed-color-000);
  font-size: var(--fs-base__two);
}

.table-filter.filter-select {
  .filter-select-__indicator {
    padding: 5px;
  }
}

.invoice-pdf {
  canvas {
    width: 100% !important;
    height: auto !important;
  }
}

.timeline:before {
  height: 85%;

  top: 5px;
}

.bfc-old-style {
  @import 'scss_old/bfc.scss';
}

// Share post modal
.modal__header {
  padding: 1rem 1.5rem !important;
  border-bottom: 1px solid #e9ecef !important;
  h4 {
    font-family: 'Segoe UI';
  }
  .close {
    background-color: rgba(0, 0, 0, 0.04);
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
    padding: 5px 10px 7px;
  }
}
.printable-modal {
  .printable-modal__header {
    @extend .modal__header;
    border-bottom: 0px !important;
    .modal__title {
      color: var(--unnamed-color-1b62ab);
      font-size: var(--fs-base__zero);
    }
  }
  .printable-modal__body {
    .bfc-otp-form {
      padding: 0 30px 30px 30px!important;
      .form__form-group {
        row-gap: 10px;
        display: grid;
        .bfc-otp-container {
          .bfc-otp-input {
            width: 40px!important;
            height: 40px;
            border-radius: 5px;
          }
        }
      }
      .bfc-center {
        .btn {
          width: 100%;
        }
      }
      .text-right {
        margin-right: 0px!important;
      }
      .bfc-OTPtext {
        p {
          text-align: center;
        }
      }
    }
  }
}
.location-modal__header {
  padding: 10px !important;
  border-bottom: none !important;
}
.modal__body {
  // padding: 1.5rem;
  padding: 0px;
  .modal__body-row {
    .user-info {
      padding: 15px;
      .profile-pic {
        width: 35px;
        height: 35px;
        border-radius: 18px;
        object-fit: cover;
      }
      .post-main {
        .filter-post-section {
          .bfc-feed {
            .bfc-feedInfo {
              padding: 15px !important;
            }
          }
        }
      }
      .form-group-field {
        .location {
          .ant-select-multiple {
            .ant-select-selector {
              // height: 38px;
              min-height: 38px;
              height: auto;
              border-radius: 5px;
              border: 1px solid #c7c7c7;
              .ant-select-selection-placeholder {
                color: #484848;
              }
              .ant-select-selection-overflow {
                .ant-select-selection-item{
                  align-items: center;
                }
              }
            }
          }
          .ant-select:not(.ant-select-disabled):hover {
            .ant-select-selector {
              border: 1px solid black;

            }
          }
        }
      }
    }
    .modal__edit-content {
      max-height: 350px;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
  .box-select__options {
    margin: 10px;
    .card {
      margin-right: 10px;
      margin-bottom: 15px;
      padding-bottom: 0px;
      box-shadow: 0px 0px 5px 0px #dfdfdf;
      .card-body {
        cursor: pointer;
        padding: 10px;
        label {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
  .modal__footer {
    .file-images {
      display: flex;
      overflow-x: auto;
      flex-wrap: nowrap;
      // width: 49vw;
      max-width: 610px;
      margin-left: 5px;
    }
  }
  // .profile-map__modal {
  //   #businessMap {
  //     .leaflet-control-container {
  //       .leaflet-top {
  //         .mmiPicker {
  //           #mmiPickerBot {
  //             .pPsubm {
  //               background-color: var(--unnamed-color-1b62ab);
  //               border-color: #1B62AB;
  //               color: var(--unnamed-color-ffffff);
  //               border-radius: 25px;
  //               display: flex;
  //               align-items: center;
  //               justify-content: center;
  //               &:hover {
  //                 background-color: var(--unnamed-color-ffffff);
  //                 color: var(--unnamed-color-1b62ab);
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  .profile-map__modal {
    .enableButton {
      .leaflet-control-container {
        .leaflet-top {
          .mmiPicker {
            #mmiPickerBot {
              .pPsubm {
                background-color: var(--unnamed-color-1b62ab);
                border-color: #1B62AB;
                color: var(--unnamed-color-ffffff);
                border-radius: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover {
                  background-color: var(--unnamed-color-ffffff);
                  color: var(--unnamed-color-1b62ab);
                }
              }
            }
          }
        }
      }
    }
    .disableButton{
      .leaflet-control-container {
        .leaflet-top {
          .mmiPicker {
            #mmiPickerBot {
              .pPsubm {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .locationModal-map {
    .leaflet-control-container {
      .leaflet-top {
        .mmiPicker {
          .default_div {
            img {
              width: auto !important;
            }
          }
        }
      }
    }
  }
  .custom-input {
    .datepicker-calendar-icon {
      position: absolute;
      right: 10px;
      top: 10px;
      pointer-events: none;
    }
  }
  .location-field-6{
    .form-group-field{
      .MuiFormControl-root{
        .MuiInputLabel-shrink{
          transform: translate(14px, -6px) scale(0.75);
        }
      }
    }
  }
}
// .modal-section{
.ant-message {
  .ant-message-notice {
    .ant-message-notice-content {
      box-shadow: 0 0px 3px 0px rgba(0, 0, 0, 0.05);
      width: 500px;
      margin-top: 50px;
      border: 1px solid #1eabac;
      border-radius: 10px;
      .ant-message-custom-content {
        .anticon-info-circle {
          display: none !important;
        }
        .matching__popup-modal {
          position: relative;
          .close-area {
            position: absolute;
            right: 5px;
            // bottom: 35px;
          }
          .middle-part {
            gap: 10px;
            .btn-gradient-2 {
              background: linear-gradient(white, white) padding-box,
                linear-gradient(to bottom, #23ffae, #1b62ab) border-box;
              border-radius: 50px;
              border: 7px solid transparent;
            }
            .btn-gradient-2 {
              height: 50px;
              width: 50px;
              position: relative;
              right: 40px;
              bottom: 0px;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              font-weight: 700;
              color: var(--unnamed-color-1b62ab);
              font-size: var(--fs-base__one);
              cursor: pointer;
              transition: color 0.2s;
              .matching-circle-skeleton {
                .ant-skeleton-content {
                  .ant-skeleton-title {
                    border-radius: 50%;
                  }
                }
              }
            }
            .heading {
              font-size: var(--fs-base__two);
              font-weight: 600;
              color: var(--unnamed-color-1b62ab);
              text-align: left;
              margin-bottom: 0px;
            }

            .matching-skeleton-subtitle {
              .ant-skeleton-content {
                .ant-skeleton-title {
                  width: 95%;
                  margin-bottom: 0px!important;
                }
              }
            }
            .ltr-support {
              width: 100px;
              .matching-skeleton-btn {
                .ant-skeleton-content {
                  .ant-skeleton-title {
                    margin-bottom: 0px!important;
                  }
                }
              }
            }
            .content {
              @extend .heading;
              font-size: var(--fs-base__four);
              font-weight: 100;
              .point {
                color: var(--unnamed-color-1b62ab);
                font-weight: 600;
              }
            }
            .view-all-btn {
              width: max-content !important;
              padding: 2px 15px !important;
            }
          }
        }
      }
    }
  }
}
// }
.view-specs__modal {
  .modal__body {
    .view-specs__card {
      padding-bottom: 0px;
      .ant-table {
        border-radius: 15px;
        .ant-table-container {
          table {
            thead {
              tr {
                th {
                  color: #636363;
                  font-weight: 100;
                }
              }
              tr:first-child {
                th:first-child {
                  border-top-left-radius: 20px;
                }
              }
              tr:first-child {
                th:last-child {
                  border-top-right-radius: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.follow-modal {
  .scrollbar {
    // .modal__body {
    // height: 480px !important;
    height: calc(100vh - 65px) !important;
    max-height: 500px;
    width: 100%;
    overflow-y: scroll !important;
    overflow-x: hidden;
    .follow-section {
      .follow-image {
        height: 60px;
        width: 60px;
        border-radius: 40px;
        background-repeat: no-repeat;
        background-size: cover;
        object-fit: cover;
      }
      .followers_list_info {
        .follow__ul {
          list-style-image: none !important;
          list-style-type: none !important;
          .follow-full-name {
            color: var(--unnamed-color-1b62ab);
            cursor: pointer;
            font-weight: 600;
          }
          .follow-company-code {
            color: var(--unnamed-color-ccc);
          }
        }
      }
      .bfc-modal-followers-button {
        padding: 5px 25px !important;
        border-radius: 30px;
        opacity: 1;
        position: relative !important;
        background-color: transparent;
        border: 1px solid var(--unnamed-color-1b62ab);
        color: var(--unnamed-color-1b62ab);
        &:before {
          background-color: var(--unnamed-color-1b62ab);
        }
        &:hover,
        &:focus,
        &:active {
          color: var(--unnamed-color-ffffff);
        }
      }
    }
  }
  #style-1::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
    border-radius: 0px 0px 25px 0px;
  }

  #style-1::-webkit-scrollbar
  {
    width: 12px;
  }

  #style-1::-webkit-scrollbar-thumb
  {
    border-radius: 0px 0px 25px 0px;
    -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,.3);
    background-color: lightgrey;
  }
}
.supplier-drill__down-modal {
  display: grid !important;
  align-items: baseline;
  justify-content: center;
  .modal-dialog {
    width: auto !important;
    margin: 0.5rem;
    // width: 850px !important;
    .modal-content {
      background-color: transparent;
      max-width: 800px !important;
      .extended-modal {
        .extended__body {
          display: inline-table;
          background-color: rgb(241, 242, 243);
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;
          .extended-container {
            height: 555px;
            max-height: calc(100vh - 100px);
            margin-top: 10px;
            overflow-y: auto;
            padding-left: 30px;
            padding-right: 30px;
            .extended__overview-card {
              padding: 10px;
              background-color: var(--unnamed-color-ffffff);
              .card-body {
                .extended__middle-part {
                  display: flex;
                  // min-height: 60px;
                  min-height: 110px;
                  gap: 30px;
                  .extended__ul {
                    list-style-type: none;
                    list-style-image: none;
                    display: flex;
                    height: 50px;
                    width: fit-content;
                    margin-bottom: 0px;
                    background-color: #edf4f7;
                    padding: 10px;
                    border-radius: 50px;
                    // box-shadow: rgb(0 0 0 / 5%) 0px 0px 5px 0px;
                    box-shadow: 0px 0px 5px 0px #dfdfdf;
                    .extended__li {
                      margin-left: 10px;
                      margin-right: 10px;
                      .count {
                        color: var(--unnamed-color-1b62ab);
                        font-weight: 800;
                      }
                    }
                  }
                }
                .close {
                  background-color: rgba(0, 0, 0, 0.04);
                  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                  border-radius: 50%;
                  padding: 5px 10px 7px;
                }
              }
              .view__more {
                color: var(--unnamed-color-1b62ab);
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 469px) {
  .extended-modal {
    .extended__body {
      overflow-y: scroll;
      height: calc(100vh - 100px);
      display: block;
    }
  }
}
.close {
  background-color: rgba(0, 0, 0, 0.04);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  padding: 5px 8px 5px;
}
.theme-light .text-editor .DraftEditor-root {
  border: none !important;
}
.btn-toolbar:last-child {
  // margin-bottom: 20px !important;
}
.public-DraftStyleDefault-block {
  margin: 0em 0 !important;
  // position: absolute !important;
  max-width: -webkit-fill-available !important;
  min-width: -webkit-fill-available !important;
  // padding: 10px !important;
}
.post-main {
  border: none;
}
.bfc-feed .transaction-post {
  border: 1px solid var(--unnamed-color-ccc) !important;
}
.filter-post-section {
  border: 1px solid var(--unnamed-color-ccc);
  border-radius: 15px;
}
.slick-slide img {
  width: 93%;
  height: 100% !important;
  margin-top: 20px;
}

// edit post

.nesting-rating-modal {
  .modal-dialog {
    width: 850px !important;
    .modal-content {
      max-width: 800px !important;
      .material-form {
        .modal__header {
          border-bottom: none !important;
          text-align: center;
        }
        .modal__body {
          // overflow: hidden !important;
          // padding: 15px;
          // height: auto !important;
        }
      }
    }
  }
}
.modal-rating__popover {
  .modal-dialog {
    width: 700px !important;
  }
  @extend .nesting-rating-modal;
}
.bfc-post-text-editor-modal {
  .material-form {
    .modal__body {
      .bfc-modal-profileName {
        margin-top: 0% !important;
        display: inline-block;
        margin-left: 15px;
      }
      .bfc-modalPostbox {
        margin: 0px !important;
        margin-top: 15px !important;
      }
    }
  }
}
//Calendar's zIndex
.react-datepicker-popper {
  z-index: 100 !important;
}

//  transctional post
.transctional__card-list {
  .selling {
    color: var(--unnamed-color-1b62ab);
    margin-top: 0px;
    margin-bottom: 20px;
    background: #f0f2f5;
    padding: 0px 20px;
    font-weight: 400 !important;
    font-size: var(--fs-base__three);
    .sell-info {
      display: flex;
      .text {
        font-size: var(--fs-base__two);
      }
    }
  }
  .selling-walk-in {
    font-size: var(--fs-base__three);
    color: var(--unnamed-color-1b62ab);
    margin-top: 0px;
    margin-bottom: 20px;
    background: #e6ffee;
    padding: 0px 20px;
    font-weight: 400 !important;
    .sell-info {
      display: flex;
    }
  }
  .card {
    padding-bottom: 0px !important;
    height: auto;
    border: 1px solid var(--unnamed-color-ccc);
    box-shadow: 0px 2px 15px 2px rgba(151, 142, 142, 0.2);
    .card-header {
      // background-color: #f1f9fc !important;
      background: linear-gradient(28deg, #eef1f5, #daeeff 17%, #eff8ff 48%, #f2f5f7 72%, #ddefff);
      // padding: 0.1rem 1rem !important;
      padding: 2px 10px;
      font-weight: 100;
      font-size: var(--fs-base__two);
      color: var(--unnamed-color-1b62ab);
    }
    .card-auction-header {
      // background-color: #f1f9fc !important;
      background: linear-gradient(28deg, #fff 0%, #dafff3 15%, #dafff3 0%, #f2f5f7 72%, #daffe7);
      padding: 2px 10px;
      font-weight: 100;
      font-size: var(--fs-base__two);
      color: var(--unnamed-color-1b62ab);
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }
    .card-body {
      padding: 0.5rem 1rem !important;
      min-height: 60px;
      .tpc-subtitle {
        font-weight: bold;
        font-size: var(--fs-base__three);
        color: var(--unnamed-color-1b62ab);
        width: 100%;
        .tpc-subtitle-small {
          font-weight: 100;
          color: var(--unnamed-color-1b62ab);
          font-size: var(--fs-base__three);
        }
      }
      .tpc-card-icon {
        float: right;
        font-size: var(--fs-base__zero);
      }
    }
  }
}
.bfc-table-list-components {
  white-space: break-spaces;
}

.record-payment-body .ant-picker-focused {
  border: 2px solid var(--unnamed-color-1b62ab) !important;
  border-right-width: 2px !important;
}

.font-styles {
  font-family: var(--unnamed-font-segoeUI);
  color: var(--unnamed-color-707070);
}

.widget-HeaderCell__value {
  color: var(--unnamed-color-707070);
}

.bfc-auction-bid-offer-modal {
  .modal-dialog {
    width: 850px;
    max-width: fit-content!important;
    .modal-content {
      max-width: unset !important;
    }
  }
}
.auction-bid-offer__modal{
  @extend .bfc-auction-bid-offer-modal;
  .modal-dialog {
    width: 850px;
    // max-width: 400px!important;
  }
}
.normal-auction-bid-offer__modal{
  @extend .auction-bid-offer__modal;
  .modal-dialog {
    width: 700px;
    max-width: none!important;
  }
}
.bfc-auction-target-price-modal {
  .modal-dialog {
    width: 450px;
    .modal-content {
      max-width: unset !important;
    }
  }
}
.MuiInputBase-input {
  font-size: var(--fs-base__two) !important;
}
.MuiBadge-badge {
  font-weight: bold !important;
  font-size: var(--fs-base__four) !important;
  font-family: var(--unnamed-font-segoeUI) !important;
}
.MuiBadge-colorSecondary {
  font-family: var(--unnamed-font-segoeUI) !important;
}
.MuiBadge-anchorOriginTopRightRectangle {
  top: 20px !important;
  right: 20px !important;
  transform: scale(1) translate(50%, -50%);
  transform-origin: 100% 0%;
}
.MuiTypography-body1 {
  font-family: var(--unnamed-font-segoeUI) !important;
  font-size: var(--fs-base__three) !important;
}
.text-link {
  font-family: var(--unnamed-font-segoeUI) !important;
  font-size: var(--fs-base__three) !important;
}
.MuiInputBase-root {
  font-family: var(--unnamed-font-segoeUI) !important;
}
.MuiChip-label {
  font-family: var(--unnamed-font-segoeUI) !important;
}
.ltr-support .bold-text {
  font-size: var(--fs-base__three) !important;
}
.ltr-support .material-form .material-form__field label {
  font-family: var(--unnamed-font-segoeUI);
}
.bottom-content {
  .b-border {
    border: 1px dashed var(--unnamed-color-ccc);
    border-radius: 10px;
    .bottom-title {
      color: var(--unnamed-color-1b62ab);
      font-size: var(--fs-base__two);
    }
  }
}
.MuiAutocomplete-option {
  font-family: var(--unnamed-font-segoeUI) !important;
}

.option-item-loc:hover {
  background: #f5f5f5 !important;
  color: #495057;
}

.option-item-loc {
  color: #495057;
  font-weight: 400;
  font-size: var(--fs-base__three);
  font-family: var(--unnamed-font-segoeUI);
}

.quality-photos-tab {
  .ant-image-img {
    height: 205px !important;
  }
}
.ant-image-preview-img {
  width: auto !important;
}

.supplier-progress__bar {
  .ant-progress-steps-outer {
    // display: block !important;
    .ant-progress-steps-item {
      width: 20px !important;
      border: 1px solid #dfdfdf;
      border-radius: 10px;
      height: 10px !important;
    }
    .ant-progress-text {
      width: 2px !important;
      margin-left: 0px !important;
    }
  }
}
.deals-progress__bar {
  .ant-progress-steps-outer {
    .ant-progress-steps-item {
      border: 1px solid #dfdfdf;
      border-radius: 10px;
      height: 5px !important;
      width: 10px !important;
    }
    .ant-progress-text {
      width: 2px !important;
      margin-left: 0px !important;
    }
  }
}
.supplier-rating-table {
  .ant-progress-steps-outer {
    // display: block !important;
    .ant-progress-steps-item {
      width: 14px !important;
      border: 1px solid #dfdfdf;
      border-radius: 10px;
      height: 10px !important;
    }
    .ant-progress-text {
      width: 2px !important;
      margin-left: 0px !important;
    }
  }
  .supplier-rating-table__discovery {
    @extend .ant-progress-steps-outer;
    .ant-progress-steps-item {
      width: 8px !important;
      height: 5px !important;
    }
  }
}

.progess-final-value {
  font-size: var(--fs-base__three);
  margin-left: 5px;
  color: var(--unnamed-color-707070);
}
.ant-switch-checked {
  // background-color: var(--unnamed-color-1890ff);
  background-color: var(--unnamed-color-1b62ab) !important;
  // background-color: #1890ff;
  background-color: #1b62ab !important;
}
.commodity-parent {
  align-items: center;
}
.comment-list {
  .comment-fieldset__or {
    // text-align: center;
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
    ::after {
      content: '';
      height: 1px;
      width: calc(80% - 90px);
      background: #f1f1f1;
      position: absolute;
      top: 10px;
      right: 20px;
    }
    ::before {
      content: '';
      height: 1px;
      width: calc(20% - 70px);
      background: #f1f1f1;
      position: absolute;
      top: 10px;
      left: 0;
    }
    p {
      margin-left: 45px;
    }
  }
}
.topbar {
  .topbar__left {
    width: auto;
  }
  .topbar__right {
    .topbar__right-inner {
      .bfc-topbar-nav-menu {
        .nav {
          padding-left: 0;
          .nav-item {
            .topbar__link {
              padding: 9px 10px;
              .topbar__btn {
                padding: 0;
              }
              .topbar__link-title {
                font-size: var(--fs-base__three);
              }
            }
            .topbar__link-indent {
              padding: 9px 5px;
              .topbar__btn {
                padding: 0;
              }
              .topbar__link-title {
                font-size: var(--fs-base__three);
              }
            }
          }
        }
      }
      .bfc-topbar-nav-menu {
        .Notification__collapse-content {
          right: 10% !important;
          border-radius: 10px;
        }
      }
    }
  }
}
@media screen and (max-width: 1199px) {
  .login-signup__page {
    .country-code {
      top: 27px!important;
    }
  }
}
@media screen and (min-width: 1024px) {
  .topbar {
    .topbar__right {
      .topbar__right-inner {
        display: flex;
        .bfc-topbar-nav-menu {
          .nav {
            padding-right: 0;
          }
        }
      }
    }
  }
  .printable-quote {
    width: 1000px;
  }
}
@media screen and (min-width: 3000px) {
  .mobile-and-laptop-view {
    visibility: visible;
    display: block;
  }
  .tab-view {
    visibility: hidden;
    display: none!important;
  }
}
@media screen and (min-width: 769px) and (max-width: 3000px) {
  .topbar {
    .topbar__right {
      .mobile-and-laptop-view {
        visibility: hidden;
        display: none!important;
      }
      .tab-view {
        display: block;
        visibility: visible;
      }
    }
  }
}
@media screen and (max-width: 769px) {
  .mobile-and-laptop-view {
    visibility: visible;
    display: block!important;
  }
  .tab-view {
    visibility: visible;
    display: flex!important;
  }
  .auction-bid-offer__modal {
    .modal-dialog {
      width: 900px;
      max-width: 700px!important;
    }
  }
}
@media screen and (min-width: 769px) {
  .topbar {
    .topbar__right {
      .topbar__right-inner {
        display: flex;
        .MuiBadge-anchorOriginTopRightRectangle {
          top: 20px !important;
          right: 5px !important;
          height: 17px !important;
          width: initial !important;
          justify-content: center;
          align-items: center;
          display: flex;
          position: absolute;
        }
      }
    }
  }
  .modal__body {
    .custom-input__both {
      .react-datepicker {
        right: 100px!important;
        .react-datepicker__triangle {
          left: 120px!important;
        }
      }
      input {
        max-height: 36px !important;
      }
    }
  }
  .normal-modal__body {
    .custom-input {
      .react-datepicker {
        right: 80px!important;
      }
      input {
        max-height: 36px !important;
      }
    }
  }
  .medium-modal__body {
    .custom-input {
      .react-datepicker {
        right: 70px!important;
      }
      input {
        max-height: 40px !important;
      }
    }
  }
}
@media screen and (max-width: 610px) {
  .nesting-rating-modal {
    .modal-dialog {
      .modal-content {
        .material-form {
          .modal__body {
            .col-md-6 {
              border-right: none !important;
            }
          }
        }
      }
    }
  }
  .bfc-post-text-editor-modal {
    .modal-dialog {
      width: 100% !important;
    }
  }
}
// @media screen and (max-width: 610px) {
//   .bfc-post-text-editor-modal {
//     .modal-dialog {
//       margin: 0;
//       width: auto!important;
//       .modal-content{
//         border-radius: 0;
//         .modal__body{
//           .bfc-modalPostbox{
//             height: calc(100vh - 230px);
//             border: none;
//             .post-editor{
//               height: calc(100vh - 250px)!important;
//             }
//           }
//         }
//         .modal__footer{
//           .bfc-modal-postbutton{
//             top: auto!important;
//           }
//         }
//       }
//     }
//   }
// }
@media screen and (min-width: 576px) and (max-width: 769px) {
  .finance-invoices-header {
    flex-flow: wrap !important;
    .list-view-header {
      .right-content-box {
        padding-right: 0 !important;
        .bfc-invoice-wrapper {
          display: block !important;
          .bfc-ref-data-select-wrapper {
            .MuiAutocomplete-root {
              width: 100% !important;
            }
          }
          .btn {
            margin-left: 0 !important;
            width: 100%;
            margin-top: 10px;
          }
        }
      }
    }
  }
  .topbar {
    min-height: 110px;
    .topbar__left {
      .topbar__logo {
        margin-top: 15px;
        display: block;
      }
    }
    .topbar__right {
      display: block !important;
      .topbar__right-inner {
        display: flex;
        width: max-content;
        margin-top: 0px;
        right: 0;
        position: absolute;
        .bfc-topbar-nav-menu {
          .nav {
            .nav-item {
              .topbar__link {
                padding: 9px 15px;
              }
              .topbar__link-indent {
                padding: 9px 5px;
              }
            }
          }
        }
        .MuiBadge-anchorOriginTopRightRectangle {
          top: 10px !important;
          right: 10px !important;
          height: 17px !important;
          width: initial !important;
          justify-content: center;
          align-items: center;
          display: flex;
          position: absolute;
        }
        .topbar__profile {
          margin: 0;
        }
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .container__wrap {
    padding-right: 0;
  }
  .ant-message {
    .ant-message-notice {
      .ant-message-notice-content {
        width: auto !important;
        margin-top: 80px !important;
        .ant-message-custom-content {
          .matching__popup-modal {
            .middle-part {
              .flex-grow-1 {
                margin-left: -10px !important;
              }
              .btn-gradient-2 {
                right: 10px;
              }
            }
          }
        }
      }
    }
  }
  .finance-invoices-header {
    flex-flow: wrap !important;
    .list-view-header {
      .right-content-box {
        padding-right: 0 !important;
        .bfc-invoice-wrapper {
          display: block !important;
          .bfc-ref-data-select-wrapper {
            .MuiAutocomplete-root {
              width: 100% !important;
            }
          }
          .btn {
            margin-left: 0 !important;
            width: 100%;
            margin-top: 10px;
          }
        }
      }
    }
  }
  .finance-header {
    .content-wrapper {
      display: contents !important;
      .top-content {
        flex-flow: column;
        .two-box {
          display: contents !important;
          .form-group {
            margin-bottom: 10px !important;
            margin-left: 0 !important;
            .form-control {
              width: 100% !important;
            }
          }
        }
      }
    }
    .right-content-box {
      .bfc-invoice-wrapper {
        flex-direction: column;
        .bfc-ref-data-select-wrapper {
          .MuiAutocomplete-root {
            width: 100% !important;
          }
        }
        .btn {
          margin-left: 0 !important;
        }
      }
    }
  }
  .modal-dialog {
    padding: .5rem;
    .modal-content {
      // border-radius: 0;
      .modal__body {
        .modal__footer {
          .file-images {
            display: flex;
            overflow-x: auto;
            flex-wrap: nowrap;
            width: 93vw;
            margin-left: 15px;
          }
        }
      }
    }
  }
  .btn-wrapper {
    display: flex;
    justify-content: center;
  }
  .topbar {
    .topbar__left {
      .topbar__logo {
        margin-top: 15px;
        display: block;
      }
    }
    .topbar__right {
      display: flex;
      // flex-wrap: wrap-reverse;
      .topbar__right-inner {
        display: flex;
        width: max-content;
        margin-top: 10px;
        .bfc-topbar-nav-menu {
          .nav {
            .nav-item {
              .topbar__link {
                padding: 9px 15px;
              }
              .topbar__link-indent {
                padding: 9px 0px;
              }
            }
          }
        }
        .MuiBadge-anchorOriginTopRightRectangle {
          top: 10px !important;
          right: 10px !important;
          height: 17px !important;
          width: initial !important;
          justify-content: center;
          align-items: center;
          display: flex;
          position: absolute;
        }
      }
    }
  }
  .transaction-post {
    .bfc-feedInfo {
      .transctional__card-list {
        .selling {
          .sell-info {
            display: block;
            .selling-content {
              text-align: center !important;
              font-size: var(--fs-base__two);
            }
          }
        }
        .selling-walk-in {
          .sell-info {
            display: block;
            .selling-content {
              text-align: center !important;
              font-size: var(--fs-base__two);
            }
          }
        }
      }
    }
  }
  .auction-bid-offer__modal {
    .modal-dialog {
      width: 900px;
      max-width: 400px!important;
    }
  }
  .info-box {
    .body {
      ul {
        display: grid!important;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        li {
          .sub-content{
            float: left;
            text-align: left;
          }
        }
      }
    }
  }
  .modal__body {
    .custom-input__both {
      .react-datepicker {
        right: 0px!important;
      }
    }
  }
  .normal-modal__body {
    .custom-input {
      .react-datepicker {
        right: 0px!important;
      }
    }
  }
  .medium-modal__body {
    .custom-input {
      .react-datepicker {
        right: 0px!important;
      }
    }
  }
  .maintenace-dialog {
    .MuiDialog-container {
      .MuiPaper-root {
        .dialog-content {
          padding-bottom: 20px;
          .server-maintenance {
            .commodity-wrapper {
              .commodities-popup {
                .row {
                  .col-md-6 {
                    .maintenance-icon {
                      width: 100%;
                      height: 100%!important;
                      border-radius: 15px;
                    }
                  }
                  .col-md-4 {
                    margin-left: 0px!important;
                    .server-maintenance-text {
                      text-align: center!important;
                    }
                    .server-maintenance-subtext {
                      text-align: center!important;
                    }
                    .btn-section {
                      text-align: center!important;
                      margin-top: 15px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .topbar {
    .topbar__right {
      .topbar__right-inner {
        .bfc-topbar-nav-menu {
          .nav {
            .nav-item {
              .topbar__link {
                padding: 9px 10px;
              }
              .topbar__link-indent {
                padding: 9px 0px;
              }
            }
          }
        }
        .topbar__profile {
          .topbar__avatar {
            padding: 0px 5px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 414px) {
  .topbar {
    .topbar__right {
      .topbar__right-inner {
        .bfc-topbar-nav-menu {
          .nav {
            .nav-item {
              .topbar__link {
                padding: 10px;
              }
              .topbar__link-indent {
                padding: 10px 0px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 414px) {
  .topbar {
    min-height: 110px;
    .topbar__right {
      .topbar__right-inner {
        margin-top: 5px;
        display: flex !important;
        .topbar__profile {
          display: flex;
          justify-content: center;
        }
        .Notification__collapse-content {
          width: 90vw !important;
          position: absolute;
          top: 110px;
          right: 0 !important;
          z-index: 1;
          .topbar__collapse-item {
            .topbar__collapse-date {
              font-size: 0.625rem;
              width: min-content;
              top: 5px;
              line-height: initial;
            }
          }
        }
        .bfc-topbar-nav-menu {
          .Notification__collapse-content {
            right: 0% !important;
          }
        }
      }
    }
  }
}
@media (max-width: 700px) and (orientation: landscape) {
  .auction-bid-offer__modal {
    .modal-dialog {
      width: 600px;
      max-width: 700px !important;
    }
  }
}
@media screen and (max-width: 376px) {
  .bfc-invoice-header {
    display: block !important;
    .content-wrapper {
      width: 100%;
      padding: 5px;
    }
    .btn-wrapper {
      width: 100%;
      margin-top: 10px !important;
      display: flex;
      .print-btn-wrapper {
        margin-right: 70px;
        .btns {
          display: flex;
        }
      }
    }
  }
  .topbar__link {
    padding: 9px 5px;
    .topbar__right {
      margin-right: auto;
    }
  }
  .topbar__link-indent {
    padding: 9px 0px;
    .topbar__right {
      margin-right: auto;
    }
  }
  .topbar__right-inner {
    .topbar__profile {
      .topbar__menu-wrap {
        .topbar__menu {
          top: 40px;
          position: absolute;
          left: 25px;
        }
      }
    }
  }
}
@media screen and (max-width: 321px) {
  .bfc-invoice-header {
    display: block !important;
    .content-wrapper {
      width: 100%;
      margin: 5px;
      padding: 5px;
    }
    .btn-wrapper {
      width: 100%;
      margin-top: 10px !important ;
      display: flex;
      .print-btn-wrapper {
        margin-right: 50px;
        .btns {
          margin: 5px 0px 5px 0px;
          margin-right: 0px !important;
          display: flex;
          display: contents;
        }
      }
    }
  }
  .topbar__avatar {
    padding: 0 0px;
  }
  .topbar__link {
    padding: 9px 10px;
  }
  .topbar__link-indent {
    padding: 9px 0px;
  }
  .transaction-post {
    .bfc-feed-row {
      display: block !important;
      .post-info {
        margin-bottom: 5px;
      }
    }
    .bfc-feedInfo {
      .transctional__card-list {
        .selling {
          .sell-info {
            display: block;
            .selling-content {
              text-align: center !important;
            }
          }
        }
      }
    }
  }
  .topbar {
    min-height: 130px;
    .topbar__right {
      .topbar__right-inner {
        display: block !important;
        .bfc-topbar-nav-menu {
          .nav {
            .nav-item {
              .topbar__link {
                padding: 9px 5px;
                .topbar__link-title {
                  font-size: var(--fs-base__four);
                }
              }
              .topbar__link-indent {
                padding: 9px 0px;
                .topbar__link-title {
                  font-size: var(--fs-base__four);
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .bfc-invoice-header {
    display: block !important;
    justify-content: end;
    .btn-wrapper {
      display: flex;
      justify-content: end;
      margin-top: -30px;
    }
  }
  .aggregation-planning-header{
    display: block !important;
    .aggregation-title {
      font-size: var(--fs-base__one);
      margin-right: 20px;
      margin-bottom: 10px;
    }
    .aggregation__row {
      margin-left: -15px !important;
      display: flex;
      margin-right: 0px;
      flex-flow: nowrap;
      margin-bottom: 10px;
      .aggregation__cols {
        width: 100%!important;
      }
    }
  }
}
@media screen and (max-width: 769px) {
  .bfc-invoice-header {
    display: block !important;
    .btn-wrapper {
      display: flex;
      flex-direction: row-reverse;
      margin-top: -38px;
      .print-btn-wrapper {
        // display: block!important;
        margin-top: 5px;
        .btns {
          margin-top: 5px;
          margin-bottom: 5px;
          margin-right: 0px !important;
          display: flex;
          justify-content: space-around;
          flex-direction: column;
          display: contents;
          .e-sign-btn {
            margin-bottom: 5px;
          }
          .pdf-btn {
            @extend .e-sign-btn;
          }
          .cr-dr-btn {
            @extend .e-sign-btn;
          }
        }
        .icons-link {
          display: flex;
          align-items: center;
          // @extend .btns;
          display: block;
          flex-direction: row;
          margin-bottom: 10px;
          margin-top: -0.5px;
        }
      }
    }
  }
  .aggregation-planning-header{
    display: block !important;
    .aggregation-title {
      font-size: var(--fs-base__one);
      margin-right: 20px;
      margin-bottom: 10px;
    }
    .aggregation__row {
      display: flex;
      margin-right: 0px;
      margin-bottom: 10px;
      row-gap: 10px;
      flex-wrap: wrap!important;
      .aggregation__cols {
        width: 200px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .aggregation-planning-header{
    .action-button-row {
      margin-top: 50px;
    }
  }
}
.basic-carsousel-img {
  // width: 100%;
  // height: 500px;
  // object-fit: contain;
  // align-self: center;
  // margin-top: 0px;
  // margin-bottom: 15px;
}
.slick-slide img {
  margin-top: 0px !important;
  width: 100% !important;
}
.description-content .row {
  margin-bottom: 15px;
}
.description-content .row .label {
  font-size: 16px;
  font-weight: 700;
  color: rgb(27, 98, 171);
}
.equipment-tabs a.active.nav-link {
  background: #1b62ab !important;
  border-bottom: 0px;
  color: #fff !important;
  font-size: 16px;
  font-weight: 700;
}
.equipment-tabs a.nav-link {
  border-bottom: 0px;
  color: rgb(27, 98, 171) !important;
  font-size: 16px;
  font-weight: 700;
}
.equipment-tabs {
  margin-top: 20px;
  // margin-bottom: 20px;
}
.equipment-details-labels {
  font-size: 16px;
  font-weight: 700;
  color: #969696;
}

.equipment-tabs .nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #1b62ab #1b62ab #1b62ab;
}
.equipment-tabs .tab-content {
  border: 1px solid #1b62ab;
  height: 200px;
  padding: 15px;
  overflow-y: auto;
  border-top: 0px;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.equipment-tabs ul.nav.nav-tabs {
  border-bottom: 2px solid #1b62ab;
  padding: 0;
}
.description-content .row .description-data,
.equipment-tabs .description-data {
  font-weight: 700;
}
.description-content {
  padding: 0 0 0 25px;
}
.ReactModal__Overlay {
  z-index: 1050 !important;
}
.comment-box {
  .MuiAvatar-root {
    margin: 0px -5px 0px -5px;
  }
}
.rdw-editor-main {
  max-height: 300px;
  min-height: 300px;
  overflow-y: auto !important;
  padding: 10px;
}
.ant-table-row-light {
  background-color: var(--unnamed-color-ffffff);
}
.ant-table-row-dark {
  background-color: #f1f2f3;
}
// @media only screen and (max-width: 576px){
//   html { font-size: calc(1em + 1vw)!important; }
// }
.ant-select-dropdown {
  width: 180px !important;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #1b62ab !important;
}
.ant-popover {
  // z-index: 1111111!important;
  z-index: 1050;
  // z-index: 1100!important;
}

.table-row-light td {
  background-color: #ffffff;
}
.table-row-auto-match {
  td {
    background-color: #fff9f5;
  }
  .ant-table-cell-fix-left:first-child {
    background-color: white;
  }
}
.ant-table-tbody {
  .table-row-match {
    &:hover {
      td {
        background: #faf9d9!important;
      }
    }
    td {
      background: #faf9d9!important;
    }
  }
}
// .table-row-match {
//   td {
//     background-color: #faf9d9;
//   }
// }
.table-row-auto-match-countered {
  td {
    background-color: #f5e5e5;
  }
  .ant-table-cell-fix-left:first-child {
    background-color: white;
  }
}

.amount-btn {
  font-size: 14px !important;
}

.inputAmount {
  font-size: '32px';
  text-align: 'right';
}

.pulse-animation {
  border-radius: 50%;
  animation: pulse 1s infinite;
}

.convert-transport-pulse {
  border-radius: 50%;
  animation:  convert-transport-pulse 1s infinite;
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(144, 214, 50, 0.4);
    box-shadow: 0 0 0 0 rgba(144, 214, 50, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(144, 214, 50, 0);
    box-shadow: 0 0 0 10px rgba(144, 214, 50, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(144, 214, 50, 0);
    box-shadow: 0 0 0 0 rgba(144, 214, 50, 0);
  }
}

@mixin pulse-animation-mixin($name, $color) {
  @keyframes #{$name} {
    0% {
      -moz-box-shadow: 0 0 0 0 ($color);
      box-shadow: 0 0 0 0 $color;
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(144, 214, 50, 0);
      box-shadow: 0 0 0 10px rgba(144, 214, 50, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(144, 214, 50, 0);
      box-shadow: 0 0 0 0 rgba(144, 214, 50, 0);
    }
  }
}

@include pulse-animation-mixin(convert-transport-pulse, #f1b14398);

.MuiOutlinedInput-adornedStart {
  padding-left: 0 !important;
}

.MuiFormLabel-root.Mui-focused .MuiFormLabel-asterisk {
  color: var(--unnamed-color-f44336);
}

.MuiInputLabel-outlined.MuiInputLabel-shrink .MuiFormLabel-asterisk {
  color: var(--unnamed-color-f44336);
}
.login-signup__page {
  .react-tel-input {
    .flag-dropdown {
      background-color: var(--unnamed-color-ffffff);
      height: 35px;
      border-radius: 4px;
      .selected-flag {
        padding: 6px!important;
        width: 80px!important;
        border-radius: 4px!important;
        &:hover {
          background-color: transparent;
        }
        .arrow {
          left: 58px!important;
          border-left: 5px solid transparent!important;
          border-right: 5px solid transparent!important;
          border-top: 5px solid #555!important;
        }
        .up {
          border-top: none!important;
        }
      }
      .country-list {
        .search-emoji {
          display: none;
        }
        .search-box {
          width: 96%;
          margin-left: 0px;
        }
      }
    }
    .open {
      border-radius: 4px!important;
    }
    .form-control {
      padding-left: 80px!important;
    }
  }
  .country-code {
    left: 24px;
    top: 31px;
    color: var(--unnamed-color-707070);
    font-size: var(--fs-base__three);
  }
  .error-msg {
    // background-color: rgba(255, 0, 0, 0.05);
    color: #f44336;
    // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    // border: 1px solid #c88682;
    // padding: 5px 10px;
    font-weight: 400;
    font-size: 12px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 5px;
    margin-left: 15px;
  }
}

.cursor-pointer{
  cursor: pointer;
}
.landed-price-calculator-icon{
  height: '48px';
  width: '48px';
  margin-top: '25px';
}
.quality-profile-clear__icon {
  position: absolute;
  right: 45px;
  top: 8px;
  padding: 3px;
  cursor: pointer;
  // background-color: #1B62AB;
  &:hover {
    padding: 3px;
    border-radius: 25px;
    background-color: #f0f0f0;
  }
}
@media only screen and (min-device-width: 810px) and (max-device-width: 1080px) and (orientation: portrait) {
  .scroll-content {
    margin-top: 70px; /* Adjust the value as needed */
  }
}

@media only screen and (min-device-width: 810px) and (max-device-width: 1080px) and (orientation: landscape) {
  .scroll-content {
    margin-top: 70px; /* Adjust the value as needed */
  }
}

.maintenace-dialog {
  .MuiDialog-container {
    .MuiPaper-root {
      .dialog-content {
        padding-bottom: 20px;
        .server-maintenance {
          .commodity-wrapper {
            .commodities-popup {
              .row {
                .col-md-6 {
                  .maintenance-icon {
                    width: 100%;
                    height: 316px;
                    border-radius: 15px;
                  }
                }
                .col-md-4 {
                  .server-maintenance-text {
                    font-size: 24.97px;
                    font-weight: 700;
                    line-height: 33.21px;
                    text-align: left;
                    color: #F04343;
                  }
                  .server-maintenance-subtext {
                    font-size: var(--fs-base__one);
                    font-weight: 600;
                    line-height: 23px;
                    text-align: left;
                    color: #797979;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.map-view-activity-dropdown {
  .MuiAutocomplete-root {
    width: 140px;
    margin-top: -9px;
    .MuiInputLabel-shrink {
      transform: translate(0, 9.5px) scale(0.75);}
  }
}

.map-view-activities-card{
  height: 35px;
  background: #e4f1ff;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.map-view-activities-card-border{
  border-bottom: 1px dashed  #BDCCDB;
}

.aggregation-planning-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px 0px;
  .aggregation-title {
    font-size: var(--fs-base__one);
    margin-right: 20px;
    margin-bottom: 10px;
  }
  .aggregation__row {
    margin-left: auto;
    display: flex;
    margin-right: 0px;
    flex-flow: nowrap;
    .aggregation__cols {
      width: 200px;
      .pick-up__buy {
        .MuiAutocomplete-root {
          .MuiFormControl-root {
            .MuiInputBase-root {
              // height: 40px;
            }
          }
        }
      }
    }
  }
}

.aggregation-form-label{
  font-weight: 500;
  margin-bottom: 1px;
  color: rgb(27, 98, 171);
}

.aggregation-form-card{
  padding: '10px';
  background-color: 'aliceblue';
  border-radius: '10px';
  margin-bottom: '25px';
};

.aggregation-form-name{
  overflow: 'hidden';
  text-overflow: 'ellipsis';
  white-space: 'nowrap';
  width: '190px';
};

.simple-activity-dropdown {
  height: 37px;
    margin-top: 4px;
    width: 120px;
    padding: 3px;
    border-radius: 4px;
    border-color: rgb(27, 98, 171);
    font-size: 16px;
    cursor: pointer;
}
.maintenace-dialog {
  .MuiDialog-container {
    .MuiPaper-root {
      .dialog-content {
        padding-bottom: 20px;
        .server-maintenance {
          .commodity-wrapper {
            .commodities-popup {
              .row {
                .col-md-6 {
                  .maintenance-icon {
                    width: 100%;
                    height: 316px;
                    border-radius: 15px;
                  }
                }
                .col-md-4 {
                  .server-maintenance-text {
                    font-size: 24.97px;
                    font-weight: 700;
                    line-height: 33.21px;
                    text-align: left;
                    color: #F04343;
                  }
                  .server-maintenance-subtext {
                    font-size: var(--fs-base__one);
                    font-weight: 600;
                    line-height: 23px;
                    text-align: left;
                    color: #797979;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.lcp_discount_field {
  input {
    color: red;
  }
  input::placeholder {
    color: initial; /* Keeps the default or a different color for the placeholder */
  }
}
.input-red-text{
  .MuiInputBase-root {
    .MuiInputBase-input{
      color: red;
    }
  }
}

.input-green-text{
  .MuiInputBase-root {
    .MuiInputBase-input{
      color: green;
    }
  }
}